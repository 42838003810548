var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"companies-main-wrapper",class:{'companies': this.companiesListType !== 'map'}},[(_vm.companiesFoundOnRequest)?_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"no-companies-found-on-request"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('title.no_companies_found')))]),_c('div',{staticClass:"subtext"},[_vm._v(_vm._s(_vm.$t('title.try_to_change_search_request')))])])]):_vm._e(),(!this.list && this.companiesListType !== 'map')?_c('mu-circular-progress',{staticClass:"demo-circular-progress",attrs:{"size":36}}):_vm._e(),(this.companiesListType !== 'map' && this.companiesListType !== 'recommended')?_c('div',{staticClass:"companies-wrapper",class:{
         'column': this.flexDirection === 'column',
         'row': this.flexDirection === 'row',
         'flex-rowwrap': this.flexDirection === 'flex-rowwrap',
         'companies-wrapper-fixed' : this.flexDirection === 'column',
         'has-dimmer' : this.perfectScrollShowDimmer
       }},[_c('span',{staticClass:"dimmer",style:({
             'opacity': this.perfectScrollShowDimmer ? 1 : 0
          })}),_c(_vm.getListComponentName(),{ref:"scrollerCompanies",tag:"component",class:[this.flexDirection, {'scroll-height': this.flexDirection === 'column'}],attrs:{"options":{
                      suppressScrollX: true,
                      wheelSpeed: 1,
                      wheelPropagation: false
                  }},on:{"ps-scroll-y":function($event){return _vm.perfectScrollHandlerY($event)}}},_vm._l((_vm.list),function(item,key){return _c('div',{key:item.external_id,ref:'companyBadge_'+item.external_id,refInFor:true,staticClass:"company",class:{
               'chosen': item.external_id === _vm.external_id && _vm.$route.name !== 'promo-page-events-companies',
               'company-last': key === _vm.list.length - 1
             },attrs:{"companyName":item.title.toLowerCase(),"tagsId":_vm.setTagsId(item.tags),"tagsParentId":_vm.setTagsParentId(item.tags),"pavilionId":_vm.setPavilionId(item)},on:{"click":function($event){return _vm.showCompany(item.external_id, $event)}}},[_c('div',{staticClass:"avatar"},[_c('img',{attrs:{"src":_vm.setCompanyAvatar(item.logo_url),"alt":"image"}})]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.truncatedText(item.title, 50)))]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.truncatedText(item.description, 54)))]),(item.country && item.city)?_c('div',{staticClass:"subtitle location"},[_vm._v(" "+_vm._s(item.city.name)+", "+_vm._s(item.country.name)+" ")]):_vm._e(),_c('div',{staticClass:"info"})]),(item.external_id === _vm.external_id && _vm.promoPageListLoading)?_c('mu-circular-progress',{staticClass:"inner-loading-circle",attrs:{"size":26}}):_vm._e(),(item.is_creator === false)?_c('div',{staticClass:"fav"},[_c('mu-checkbox',{attrs:{"uncheck-icon":"star_outline","checked-icon":"star"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleFavorite(item.external_id, item.is_favorite)}},model:{value:(item.is_favorite),callback:function ($$v) {_vm.$set(item, "is_favorite", $$v)},expression:"item.is_favorite"}})],1):_vm._e()],1)}),0)],1):_vm._e(),(this.companiesListType === 'recommended')?_c('div',{staticClass:"no-events"},[(_vm.listSource === 'recommended')?_c('span',[_vm._v(_vm._s(_vm.$t('events.noRecommendedEventsYet')))]):_c('span',[_vm._v(_vm._s(_vm.$t('events.noEventsYet')))])]):_vm._e(),(this.companiesListType !== 'recommended' && this.companiesListType !== 'map')?_c('router-view',{staticClass:"two-cols-wrapper",attrs:{"name":"a"}}):_vm._e(),(this.companiesListType === 'map')?_c('seatPlan'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }