<template>
  <div class="companies-main-wrapper" :class="{'companies': this.companiesListType !== 'map'}">
    <!--    <div class="head-company-nav" v-if="list">{{list.length}} companies</div>-->
    <div v-if="companiesFoundOnRequest" class="text-center">
      <div class="no-companies-found-on-request">
        <div class="text">{{ $t('title.no_companies_found') }}</div>
        <div class="subtext">{{ $t('title.try_to_change_search_request') }}</div>
      </div>
    </div>
    <mu-circular-progress
      v-if="!this.list && this.companiesListType !== 'map'"
      class="demo-circular-progress"
      :size="36"></mu-circular-progress>

    <div class="companies-wrapper"
         :class="{
           'column': this.flexDirection === 'column',
           'row': this.flexDirection === 'row',
           'flex-rowwrap': this.flexDirection === 'flex-rowwrap',
           'companies-wrapper-fixed' : this.flexDirection === 'column',
           'has-dimmer' : this.perfectScrollShowDimmer
         }"
         v-if="this.companiesListType !== 'map' && this.companiesListType !== 'recommended'">
      <span class="dimmer"
            :style="{
               'opacity': this.perfectScrollShowDimmer ? 1 : 0
            }"></span>

      <component v-bind:is="getListComponentName()" :options="{
                        suppressScrollX: true,
                        wheelSpeed: 1,
                        wheelPropagation: false
                    }"
                 :class="[this.flexDirection, {'scroll-height': this.flexDirection === 'column'}]"
                 @ps-scroll-y="perfectScrollHandlerY($event)"
                 ref="scrollerCompanies"
      >
        <div v-for="(item, key) in list"
             :key="item.external_id"
             class="company"
             :companyName="item.title.toLowerCase()"
             :tagsId="setTagsId(item.tags)"
             :tagsParentId="setTagsParentId(item.tags)"
             :pavilionId="setPavilionId(item)"
             @click="showCompany(item.external_id, $event)"
             :ref="'companyBadge_'+item.external_id"
             :class="{
                 'chosen': item.external_id === external_id && $route.name !== 'promo-page-events-companies',
                 'company-last': key === list.length - 1
               }">
          <div class="avatar">
            <img :src="setCompanyAvatar(item.logo_url)" alt="image"/>
          </div>
          <div class="content">
            <div class="title">{{ truncatedText(item.title, 50) }}</div>
            <div class="subtitle">{{ truncatedText(item.description, 54) }}</div>
            <div class="subtitle location" v-if="item.country && item.city">
              {{ item.city.name }}, {{ item.country.name }}
            </div>
            <div class="info"></div>
          </div>
          <mu-circular-progress v-if="item.external_id === external_id && promoPageListLoading"
                                class="inner-loading-circle"
                                :size="26"></mu-circular-progress>
          <div class="fav" v-if="item.is_creator === false">
            <mu-checkbox
              v-model="item.is_favorite"
              uncheck-icon="star_outline"
              checked-icon="star"
              @click.stop="toggleFavorite(item.external_id, item.is_favorite)"
            ></mu-checkbox>
          </div>
        </div>
      </component>

    </div>
    <div v-if="this.companiesListType === 'recommended'" class="no-events">
      <span v-if="listSource === 'recommended'">{{ $t('events.noRecommendedEventsYet') }}</span>
      <span v-else>{{ $t('events.noEventsYet') }}</span>
    </div>
    <router-view
      class="two-cols-wrapper"
      v-if="this.companiesListType !== 'recommended' && this.companiesListType !== 'map'"
      name="a"></router-view>
    <seatPlan v-if="this.companiesListType === 'map'"></seatPlan>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import seatPlan from '../seatPlan/seatPlan';
import htmlDiv from '../../htmlElements/div';

export default {
  name: 'promo-companies',
  components: { seatPlan, htmlDiv },
  props: {
    listSource: {
      type: String,
      default: 'all'
    }
  },
  computed: {
    ...mapState({
      promoPageList: state => state.promoStore.promoPageList,
      companiesListType: state => state.promoStore.companiesListType,
      promoPageListLoading: state => state.promoStore.promoPageListLoading,
      companiesFoundOnRequest: state => state.promoStore.companiesFoundOnRequest
    }),
    flexDirection() {
      return this.$route.params.external_id ? 'column' : 'row'
    },
  },
  watch: {
    promoPageList: {
      deep: true,
      handler() {
        this.list = this.promoPageList; // NB: may be needed to spread-clone in case this.list has to be manipulated somehow
      }
    },
    companiesListType: {
      immediate: true,
      handler(newValue, oldValue) {
        this.list = null;
        this.callPromoPageList();
        if (oldValue !== 'map' && oldValue) {
          this.$router.push({ name: 'promo-page-events-companies' }).catch(() => {
          });
        }
      }
    },
  },
  data() {
    return {
      list: null,
      favorite: false, //TODO: need set is_favorite,
      external_id: this.$route.params.external_id ? this.$route.params.external_id : '',
      event_id: null,
      isCheckedId: [],
      chosen: false,
      customOffset: 0,
      perfectScrollShowDimmer: false,
      perfectScrollScrollTop: 0
    }
  },
  created() {
    this.event_id = Number(this.$route.params.eventId);
  },
  mounted() {
    if (this.$route.params.external_id) {
      this.scrollOnTopByExternalId();
    }
  },
  methods: {
    ...mapActions("promoStore", ["promoPageListMy", "promoPageListAll"]),
    getListComponentName() {
      if (this.flexDirection === 'column') {
        return 'perfect-scrollbar';
      }
      return 'htmlDiv';
    },
    perfectScrollHandlerY(event) {
      if (!event || !event.target) {
        return;
      }

      // show gradient dimmer at top
      if (event.target.scrollTop > 0) {
        this.perfectScrollShowDimmer = true;
      } else {
        this.perfectScrollShowDimmer = false;
      }

      this.perfectScrollScrollTop = event.target.scrollTop + 25; // account for padding
    },
    callPromoPageList() {
      if (this.companiesListType === "own") {
        let data = {
          event_id: this.$route.params.eventId,
          external_id: this.external_id
        };
        this.promoPageListMy(data);
      }
      if (this.companiesListType === "all" || this.companiesListType === "map" || !this.companiesListType) {
        this.promoPageListAll({ event_id: this.$route.params.eventId });
      }
    },
    async showCompany(external_id, event) {
      this.external_id = external_id;

      // Click on «toggle favorite» should not click on the company
      if (!event.target.classList.contains('material-icons')) { // TODO: remove .material-icons class dependency, use our own classname
        await this.$store.dispatch("promoStore/getPromoPage", {
          event_id: this.$route.params.eventId,
          external_id: external_id
        });
        await this.$router.push({
          name: 'promo-page-events-company', params: {
            eventId: this.$route.params.eventId,
            external_id: external_id
          }
        }).catch(() => {});
      }
      this.scrollOnTopByExternalId(external_id);
    },
    setCompanyAvatar(url) {
      let noPhoto;
      if (url) {
        return url
      } else {
        noPhoto = require('../../../../assets/images/no-avatar-company-300x300.png');
      }
      return noPhoto;
    },
    toggleFavorite(external_id, boolean) {
      let payload = {
        event_id: this.event_id,
        external_id: external_id
      };

      if (boolean) {
        this.$store.dispatch('promoStore/addFavPromoPage', payload);
      } else {
        this.$store.dispatch('promoStore/deleteFavPromoPage', payload);
      }


    },
    truncatedText(text, length) {
      if (text) {
        if (!length || length < 0) {
          length = text.length;
        }
        if (text.length > length) {
          return `${text.substring(0, length)}...`
        }
        return text;
      }
    },
    uploadFile() {
      let data = {
        filename: "some_file",
        url: "https://file-examples.com/wp-content/uploads/2017/02/file-sample_1MB.docx",
      };

      this.$store.dispatch("userStore/uploadFile", data);
    },

    getUserProfile() {
      this.$store.dispatch("userStore/getUserProfile");
    },
    scrollOnTopByExternalId(externalId) {
      if (typeof externalId == 'undefined') {
        externalId = this.$route.params.external_id;
      }
      const chosen = this.$refs['companyBadge_' + externalId];
      if ((typeof externalId == 'undefined') || !chosen) {
        setTimeout(this.scrollOnTopByExternalId, 333);
        return;
      }
      this.scrollOnTop({
        target: chosen[0]
      });
    },
    scrollOnTop(event) {
      let scroller = this.$refs.scrollerCompanies.$el;
      let halfHeight = 70;
      let difference = Math.max(0, event.target.offsetTop - halfHeight) - scroller.scrollTop;
      let count = 10; // no more than 60 timeouts
      let step = difference / 10; // pixel amount
      const stepper = function () {
        scroller.scrollTop += step;
        count--;
        if (count > 0) {
          setTimeout(stepper, 11);
        }
      };
      setTimeout(stepper, 11);
      return false; // stopping from passing the event into further scroll handlers
    },
    setTagsId(tags) {
      let idArr = [];
      if (tags) {
        tags.forEach((item) => {
          if (item.id) {
            idArr.push(item.id);
          }
        });
        return idArr;
      }
    },
    setTagsParentId(tags) {
      let parentIdArr = [];
      if (tags) {
        tags.forEach(item => {
          if (item.parent_id) {
            parentIdArr.push(item.parent_id)
          }
        });
        return parentIdArr;
      }
    },
    setPavilionId(item) {
      if (!item.pavilion) { return }
      return item.pavilion.id;
    }
  }
}

</script>

<style scoped lang="scss">
.companies-main-wrapper {
  width: 100%;
}

// AW-734
.two-cols-wrapper {
  width: calc(100% - 500px); // 100% - ширина колонки со списком - паддинги страницы левый правый - ширина сайдбара
}

.scroll-height {
  height: calc(100vh - 207px);
  position: relative;
  width: 420px;
}

.head-company-nav {
  text-align: left;
  font-family: Point, "Point Regular", sans-serif;
  font-size: 10px;
  letter-spacing: 0;
  color: #b5b5b5;
  position: relative;
  padding: 8px 0 8px 10px;
}

.companies {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  /* TODO: fix bad classname choice — .row gets unwanted styling from Muse UI
   * this rule removes unwanted styling from Muse UI
   */
  > .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.companies-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
  border-radius: 25px;
  padding-bottom: 30px;
  padding-left: 8px;
  padding-right: 8px;
  height: auto;
  z-index: 1;
}

.chosen {
  transform: translate(30px, 0);

  .fav {
    display: none;
  }
}

.head-nav {
  text-align: left;
  font-family: Point, "Point Regular", sans-serif;
  font-size: 10px;
  letter-spacing: 0;
  color: #b5b5b5;
  padding-left: 38px;
  padding-top: 28px;
  padding-bottom: 10px;
}

.flex-rowwrap {
  flex-flow: row wrap;
}

.column {
  flex-flow: column wrap;
}

.company {
  //width: 392px;
  //height: 100px;
  width: 400px;
  height: 130px;
  background: $biletum-white 0 0 no-repeat padding-box;
  box-shadow: 10px 10px 30px #3754aa1a;
  border-radius: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 23px;
  margin: 0 8px 10px;
  cursor: pointer;

  .avatar {
    width: 56px;
    height: 56px;
    border-radius: 5px;
    opacity: 1;

    position: relative;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }


  .content {
    padding-left: 11px;
    align-self: flex-start;
    max-width: 230px;
    line-height: 1.1;

    .title {
      text-align: left;
      font-size: 1.3rem;
      font-family: Point, "Point Regular", sans-serif;
      font-weight: bold;
      letter-spacing: 0;
      color: $biletum-black;
      max-width: 240px;
    }

    .subtitle {
      text-align: left;
      font-family: Point, "Point Regular", sans-serif;
      font-size: 1.2rem;
      line-height: 1.15;
      letter-spacing: 0;
      color: $biletum-black;
      word-wrap: break-word;
    }

    .subtitle.location {
      margin-top: 0.5rem;
      opacity: 0.5;
    }

    .info {
      text-align: left;
      font-size: 8px;
      font-family: Point, "Point Regular", sans-serif;
      letter-spacing: 0;
      color: $biletum-black;
    }
  }

  .fav {
    margin-left: auto;
  }
}


/* AW-415 */
.companies-wrapper-fixed {
  padding-left: 402px;
  padding-top: 25px;
  position: relative;

  .column {
    position: fixed;
    //margin-left: -400px;
    margin-left: -412px;
    height: calc(100vh - 188px);

    .company {
      transition: transform 0.2s, border-radius 0.2s;
    }

    .company-last {
      margin-bottom: 200px; /* Чтобы можно было скроллиться дальше при выборе .chosen */
    }
  }

  .dimmer {
    width: 415px;
    height: 60px;
    pointer-events: none;
    position: fixed;
    transition: opacity 0.5s;
    left: 87px;
    top: 178px;
    z-index: 2;
    background-image: linear-gradient(to bottom, rgba(244, 244, 244, 1) 0, rgba(244, 244, 244, 1) 25px, rgba(244, 244, 244, 0));
  }
}

/* AW-415 END --------- */

.no-events {
  padding: 34px;
  margin: auto;
}

.demo-circular-progress {
  margin-left: auto;
  margin-right: auto;
}

.inner-loading-circle {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.text-center {
  margin: 0 auto;
}

.no-companies-found-on-request {

  font-family: Point, sans-serif;
  font-style: normal;

  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.168824px;
  text-transform: capitalize;

  color: black;

  .text {
    font-weight: 600;
  }

  .subtext {
    opacity: 0.5;
  }

}

</style>
