<template>
  <div class="event-map">
    <div
        v-if="currentPromoPage"
        class="companies-wrapper"
        :class="{ 'show-left': popupShowLeft, 'show-top': popupShowTop }"
        :style="`top: ${clientY}px; left: ${clientX}px`"
        ref="companySeatplanPopup"

    >
      <div class="company">
        <div class="avatar">
          <img :src="currentPromoPageAvatarSrc" alt="image"/>
        </div>
        <div class="content">
          <div class="title">{{ currentPromoPage.title }}</div>
          <!--<div class="subtitle">{{truncatedText(list.description)}}</div>-->
          <div class="info"></div>
        </div>
      </div>
      <!--<div class="description">{{truncatedText(list.description)}}</div>-->
      <div class="description">
        <truncate
            action-class="more-btn"
            :length="90"
            :less="$t('button.less')"
            :clamp="$t('button.more')"
            :text="currentPromoPage.description || ''"
        ></truncate>
      </div>
      <div class="action-btns">
        <div class="l-side">
          <a
              class="link"
              @click.stop="viewAll('promo-page-events-company', currentPromoPage.external_id)"
              href="javascript:void(0);"
          >
            <IconMessage/>
          </a>
          <a
              class="link"
              href="javascript:void(0);"
              @click="toggleFavorite()"
          >
            <IconStar :color="favoriteStarColor"/>
          </a>
        </div>
        <div class="r-side">
          <a class="link" href="javascript:void(0);"
             @click="viewAll('promo-page-events-company', currentPromoPage.external_id)">
            {{$t('button.show_company')}}
          </a>
        </div>
      </div>
    </div>

    <div v-if="planIsLoading || promoPageListLoading" class="loading">
      <mu-circular-progress
          :width="22"
      ></mu-circular-progress>
    </div>

    <div class="seatplan-viewer-container online" v-if="isSeatplanVisible">
      <iframe
        ref="setPlanIframe"
        class="seat-plan-iframe"
        :src="seatPlanIframeSrc"
        @load="onSeatPlanIframeLoad"
      ></iframe>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import truncate from 'vue-truncate-collapsed';
import IconStar from "@/_modules/icons/components/icon-star.vue";
import IconMessage from "@/_modules/icons/components/icon-message.vue";

const NO_AVATAR_COMPANY = require('../../../../assets/images/no-avatar-company-300x300.png');

export default {
  name: 'seat-plan',
  components: {truncate, IconStar, IconMessage},
  computed: {
    ...mapState({
      planEventId: state => state.seatplanStore.eventId,
      planIsLoading: state => state.seatplanStore.isLoading,
      planJson: state => state.seatplanStore.planJson,
      promoPageListLoading: state => state.promoStore.promoPageListLoading,
      promoPageList: state => state.promoStore.promoPageList,
    }),
    isSeatplanVisible() {
      return (
        this.planEventId === this.$route.params.eventId
        && !this.planIsLoading
        && !this.promoPageListLoading
        && !!this.planJson
      );
    },
    favoriteStarColor() {
      if (!this.currentPromoPage || !this.currentPromoPage.is_favorite) {
        return '#000';
      } else {
        return '#00b6f8';
      }
    },
    currentPromoPageAvatarSrc() {
      if (!this.currentPromoPage || !this.currentPromoPage.logo_url) {
        return NO_AVATAR_COMPANY;
      } else {
        return this.currentPromoPage.logo_url;
      }
    },
    seatPlanIframeSrc() {
      return `/${this.$route.params.lang}/seat-plan`;
    }
  },
  watch: {
    promoPageList: {
      immediate: true,
      handler() {
        this.promoPageMapByExternalId.clear();
        if (!this.promoPageList || !this.promoPageList.length) {
          return;
        }
        this.promoPageList.forEach(promoPage => {
          if (promoPage.external_id) {
            this.promoPageMapByExternalId.set(promoPage.external_id, promoPage);
          }
        });
      }
    },
  },
  created() {
    this.eventId = this.$route.params.eventId;
    document.addEventListener('click', this._onDocumentClick);
  },
  mounted() {
    this._getSeatplanJson();
  },
  destroyed() {
    document.removeEventListener('click', this._onDocumentClick);
    if (this.$refs.setPlanIframe) {
      this.$refs.setPlanIframe.contentWindow.document.removeEventListener('click', this._onDocumentClick);
    }
    if (this.seatPlanViewer) {
      this.seatPlanViewer.removeEventListener('layerchange', this.onViewerLayerChange);
      this.seatPlanViewer.removeEventListener('shapeclick', this.onViewerShapeClick);
    }
  },
  data: () => ({
    eventId: null,
    currentPromoPage: null,
    clientX: null,
    clientY: null,
    popupShowLeft: false,
    popupShowTop: false,
    promoPageMapByExternalId: new Map(),
    seatPlanViewer: null,
  }),
  methods: {
    truncatedText(text) {
      return text ? `${text.substring(0, 60)}...` : text;
    },
    viewAll(name, externalId) {
      if (externalId === this.$route.params.external_id) {
        this.$store.dispatch('promoStore/setCompaniesListType', 'list');
      } else if (name && externalId) {
        this.$store.dispatch('promoStore/setCompaniesListType', 'list')
        .then(() => {
          this.$router.push({ name: name, params: { external_id: externalId } });
        })
      }
    },
    async toggleFavorite() {
      if (!this.currentPromoPage) {
        return;
      }
      this.favorite = !this.favorite;

      // ToDo: is it doing something?
      await this.$emit("promoFavoriteCompanyEvent", !this.currentPromoPage.is_favorite);

      this.currentPromoPage.is_favorite = !this.currentPromoPage.is_favorite;
    },
    onViewerShapeClick(event) {
      this.currentPromoPage = null;
      const { detail: { shape, originalEvent } } = event;

      if (shape.TYPE !== 'booth' || !shape.label) {
        return;
      }

      const promoPage = this.promoPageMapByExternalId.get(shape.label);
      if (!promoPage) {
        return;
      }
      this.popupShowLeft = false;
      this.popupShowTop = false;

      let x = originalEvent.clientX;
      let y = originalEvent.clientY;

      if (this.$refs.setPlanIframe) {
        const boundingRect = this.$refs.setPlanIframe.getBoundingClientRect();
        if (boundingRect) {
          x += boundingRect.x;
          y += boundingRect.y;
        }
      }

      this.clientX = x;
      this.clientY = y;
      this.currentPromoPage = promoPage;
      originalEvent.stopPropagation();

      this.$nextTick(() => {
        this._checkCompanyPopupPosition();
      });
    },
    onViewerShapeMouseOut() {
      this.currentPromoPage = null;
    },
    onCompanyPopupClick(event) {
      event.stopPropagation();
    },
    onViewerLayerChange(event) {
      const layer = event.detail;
      const shapes = layer && layer.getShapes();
      if (!shapes || !shapes.length) {
        return;
      }

      shapes.forEach(shape => {
        if (shape.TYPE !== 'booth') {
          return;
        }
        const label = shape.label;
        if (!label) {
          return;
        }
        const company = this.promoPageMapByExternalId.get(label);
        if (!company) {
          return;
        }

        if (company.logo_url) {
          shape.backgroundImage = company.logo_url;
        } else {
          shape.title = company.title;
        }
      });
    },
    onSeatPlanIframeLoad() {
      if (!this.$refs.setPlanIframe) {
        return;
      }
      const seatPlanContainer = this.$refs.setPlanIframe.contentWindow.document.getElementById('seat-plan-container');
      if (!seatPlanContainer) {
        return;
      }
      const seatPlanViewer = seatPlanContainer.firstChild;
      if (!seatPlanViewer) {
        return;
      }

      this.seatPlanViewer = seatPlanViewer;
      this.$refs.setPlanIframe.contentWindow.document.addEventListener('click', this._onDocumentClick);
      this.seatPlanViewer.addEventListener('layerchange', this.onViewerLayerChange);
      this.seatPlanViewer.addEventListener('shapeclick', this.onViewerShapeClick);
      this.seatPlanViewer.setAttribute('seatplan', this.planJson);
    },
    _getSeatplanJson() {
      this.$store.dispatch('seatplanStore/getSeatplanJson', {
        eventId: this.eventId,
      });
    },
    _onDocumentClick() {
      this.currentPromoPage = null;
    },
    _checkCompanyPopupPosition() {
      if (!this.$refs.companySeatplanPopup) {
        return;
      }
      const popupClientRect = this.$refs.companySeatplanPopup.getBoundingClientRect();
      if (!popupClientRect) {
        return;
      }

      if (document.documentElement.clientWidth <= this.clientX + popupClientRect.width) {
        this.popupShowLeft = true;
      }
      if (document.documentElement.clientHeight <= this.clientY + popupClientRect.height) {
        this.popupShowTop = true;
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .event-map {
    padding-right: 10px;

    .loading {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .seatplan-viewer-container {
      width: 100%;
      height: 79vh;

      .seat-plan-iframe {
        border: 0;
        width: 100%;
        overflow: hidden;
        height: 79vh;
      }
    }

    .companies-wrapper {
      position: fixed;
      height: auto;
      background: $biletum-white 0 0 no-repeat padding-box;
      box-shadow: 20px 20px 40px #3754aa1a;
      border-radius: 25px;
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: center;
      cursor: auto;
      z-index: 99;

      .company {
        width: 392px;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 23px;

        .avatar {
          width: 56px;
          height: 56px;
          border-radius: 5px;
          opacity: 1;

          position: relative;
          overflow: hidden;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }


        .content {
          padding-left: 11px;
          align-self: flex-start;
          max-width: 230px;
          line-height: 1.1;

          .title {
            text-align: left;
            font-size: 15px;
            font-family: Point, "Point Regular", sans-serif;
            font-weight: bold;
            letter-spacing: 0;
            color: $biletum-black;
            max-width: 240px;
          }

          .subtitle {
            text-align: left;
            font-family: Point, "Point Regular", sans-serif;
            font-size: 13px;
            line-height: 1.15;
            letter-spacing: 0;
            color: $biletum-black;
            margin-top: 4px;
            word-wrap: break-word;
          }

          .info {
            text-align: left;
            font-size: 8px;
            font-family: Point, "Point Regular", sans-serif;
            letter-spacing: 0;
            color: $biletum-black;
          }
        }

        .fav {
          margin-left: auto;
        }
      }

      .description {
        text-align: left;
        font-family: Point, "Point Regular", sans-serif;
        font-size: 13px;
        line-height: 1.15;
        letter-spacing: 0;
        color: $biletum-black;
        word-wrap: break-word;

        width: 392px;
        padding: 0 23px 23px;
        margin: 4px 8px 10px;
      }

      .action-btns {
        width: 100%;
        padding: 16px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .l-side {
          width: 50%;
          display: flex;
          justify-content: flex-start;
        }

        .link {
          margin-right: 14px;
        }
      }
    }

    .companies-wrapper.show-left {
      transform: translateX(-100%);
    }

    .companies-wrapper.show-top {
      transform: translateY(-100%);
    }

    .companies-wrapper.show-left.show-top {
      transform: translate(-100%, -100%);
    }

    svg:not(:root) {
      overflow: visible !important;
    }
  }
</style>

<style lang="scss">
  .more-btn {
    text-align: left;
    font-family: Point, sans-serif;
    font-size: 8px;
    color: #00b6f8 !important;
  }
</style>
