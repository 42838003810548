<template>
  <svg :width="width" :height="width" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g :style="style">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M31 15.5C31 6.93958 24.0604 0 15.5 0C6.93958 0 0 6.93958 0 15.5C0 24.0604 6.93958 31 15.5 31C24.0604 31 31 24.0604 31 15.5ZM9.73506 1.85254C11.5606 1.0804 13.5002 0.688889 15.5 0.688889C17.4998 0.688889 19.4394 1.0804 21.2649 1.85254C23.0286 2.59848 24.6126 3.66652 25.973 5.02696C27.3335 6.38741 28.4015 7.97144 29.1475 9.73506C29.9196 11.5606 30.3111 13.5002 30.3111 15.5C30.3111 17.4998 29.9196 19.4394 29.1475 21.2649C28.4015 23.0286 27.3335 24.6126 25.973 25.973C24.6126 27.3335 23.0286 28.4015 21.2649 29.1475C19.4394 29.9196 17.4998 30.3111 15.5 30.3111C13.5002 30.3111 11.5606 29.9196 9.73506 29.1475C7.97144 28.4015 6.38741 27.3335 5.02696 25.973C3.66652 24.6126 2.59848 23.0286 1.85254 21.2649C1.0804 19.4394 0.688889 17.4998 0.688889 15.5C0.688889 13.5002 1.0804 11.5606 1.85254 9.73506C2.59848 7.97144 3.66652 6.38741 5.02696 5.02696C6.38741 3.66652 7.97144 2.59848 9.73506 1.85254Z"
      />
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M16.0001 7.38636L18.6609 12.7074L24.6087 13.5682L20.3044 17.7077L21.3218 23.5557L16.0001 20.7909L10.6783 23.5534L11.6957 17.7054L7.39136 13.5682L13.3392 12.7097L16.0001 7.38636Z"
            stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>

</template>

<script>
import Vue from 'vue';

const IconStar = Vue.extend({
  name: 'icon-star',
  props: {
    color: {
      type: String,
      default: '#00B6F8',
    },
    width: {
      type: Number,
      default: 22
    }
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    style() {
      return `stroke: ${this.color}; fill:${this.color}}`;
    },
  },
});

export default IconStar;
</script>

<style>
  /* stylelint-disable */
  .cls-1,
  .cls-3 {
    fill: none;
  }

  .cls-1 {
    stroke: #000;
  }

  .cls-2 {
    stroke: none;
  }
</style>
